@font-face {
  font-family: 'bw-icons';
  src: url('./bw-icons.woff2?et1bse') format('woff2'),
    url('./bw-icons.woff?et1bse') format('woff'),
    url('./bw-icons.ttf?et1bse') format('truetype');
  font-style: normal;
  font-display: block;
}

[class^='bwicon-'],
[class*=' bwicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bw-icons', sans-serif !important;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bwicon-american-football-indicator .path1:before {
  content: '\ec71';
  color: rgb(172, 72, 22);
}
.bwicon-american-football-indicator .path2:before {
  content: '\ece9';
  margin-left: -1em;
  color: rgb(219, 102, 43);
}
.bwicon-american-football-indicator .path3:before {
  content: '\eceb';
  margin-left: -1em;
  color: rgb(245, 245, 245);
}
.bwicon-tennis-indicator:before {
  content: '\ec72';
  color: #ffd789;
}
.bwicon-icon-bet-builder:before {
  content: '\ecea';
}
.bwicon-icon-clock-arm:before {
  content: '\ec61';
}
.bwicon-icon-clock-frame:before {
  content: '\ec62';
}
.bwicon-icon-checked:before {
  content: '\ec66';
}
.bwicon-icon-category-betjack-specials:before {
  content: '\ec6c';
}
.bwicon-icon-top-virtuals:before {
  content: '\ec68';
  color: #727272;
}
.bwicon-calendar:before {
  content: '\ec6f';
}
.bwicon-globe:before {
  content: '\ec70';
}
.bwicon-odds-1x2-us:before {
  content: '\ece4';
}
.bwicon-stats:before {
  content: '\ec69';
}
.bwicon-odds-1x2-eu:before {
  content: '\ece6';
}
.bwicon-payment_method_card_visa:before {
  content: '\ecbf';
  color: #1434cb;
}
.bwicon-payment_method_card_mastercard .path1:before {
  content: '\ecc0';
  color: rgb(255, 95, 0);
}
.bwicon-payment_method_card_mastercard .path2:before {
  content: '\ecc1';
  margin-left: -1.4111328125em;
  color: rgb(235, 0, 27);
}
.bwicon-payment_method_card_mastercard .path3:before {
  content: '\ecc2';
  margin-left: -1.4111328125em;
  color: rgb(247, 158, 27);
}
.bwicon-payment_method_card_mastercard .path4:before {
  content: '\ecc3';
  margin-left: -1.4111328125em;
  color: rgb(247, 158, 27);
}
.bwicon-payment_method_card_discover .path1:before {
  content: '\ecc4';
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path2:before {
  content: '\ecc5';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path3:before {
  content: '\ecc6';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path4:before {
  content: '\ecc7';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path5:before {
  content: '\ecc8';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path6:before {
  content: '\ecc9';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path7:before {
  content: '\ecca';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path8:before {
  content: '\eccb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_card_discover .path9:before {
  content: '\eccc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.65;
}
.bwicon-payment_method_card_discover .path10:before {
  content: '\eccd';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-payment_method_card_discover .path11:before {
  content: '\ecce';
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.bwicon-default_payment_method_logo:before {
  content: '\ec82';
  color: #073bc5;
}
.bwicon-payment_method_paypal_large .path1:before {
  content: '\ec79';
  color: rgb(0, 48, 135);
}
.bwicon-payment_method_paypal_large .path2:before {
  content: '\ec7a';
  margin-left: -3.7626953125em;
  color: rgb(0, 48, 135);
}
.bwicon-payment_method_paypal_large .path3:before {
  content: '\ec7b';
  margin-left: -3.7626953125em;
  color: rgb(0, 48, 135);
}
.bwicon-payment_method_paypal_large .path4:before {
  content: '\ec7c';
  margin-left: -3.7626953125em;
  color: rgb(0, 112, 224);
}
.bwicon-payment_method_paypal_large .path5:before {
  content: '\ec7d';
  margin-left: -3.7626953125em;
  color: rgb(0, 112, 224);
}
.bwicon-payment_method_paypal_large .path6:before {
  content: '\ec7e';
  margin-left: -3.7626953125em;
  color: rgb(0, 112, 224);
}
.bwicon-payment_method_paypal_large .path7:before {
  content: '\ec7f';
  margin-left: -3.7626953125em;
  color: rgb(37, 59, 128);
}
.bwicon-payment_method_paypal_large .path8:before {
  content: '\ec80';
  margin-left: -3.7626953125em;
  color: rgb(0, 112, 224);
}
.bwicon-payment_method_paypal_large .path9:before {
  content: '\ec81';
  margin-left: -3.7626953125em;
  color: rgb(0, 28, 100);
}
.bwicon-payment_method_paypal_large .path10:before {
  content: '\ec78';
  margin-left: -3.7626953125em;
  color: rgb(0, 48, 135);
}
.bwicon-payment_method_paypal_small .path1:before {
  content: '\ec83';
  color: rgb(37, 59, 128);
}
.bwicon-payment_method_paypal_small .path2:before {
  content: '\ec9d';
  margin-left: -0.849609375em;
  color: rgb(0, 112, 224);
}
.bwicon-payment_method_paypal_small .path3:before {
  content: '\ec9e';
  margin-left: -0.849609375em;
  color: rgb(0, 28, 100);
}
.bwicon-payment_method_paypal_small .path4:before {
  content: '\ec9f';
  margin-left: -0.849609375em;
  color: rgb(0, 48, 135);
}
.bwicon-payment_method_trustly_bank_transfer_large .path1:before {
  content: '\ec9c';
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path2:before {
  content: '\eca0';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path3:before {
  content: '\eca1';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path4:before {
  content: '\eca2';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path5:before {
  content: '\eca3';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path6:before {
  content: '\eca4';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path7:before {
  content: '\eca5';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path8:before {
  content: '\eca6';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path9:before {
  content: '\eca7';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path10:before {
  content: '\eca8';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path11:before {
  content: '\eca9';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path12:before {
  content: '\ecaa';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path13:before {
  content: '\eccf';
  margin-left: -1.9091796875em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_large .path14:before {
  content: '\ecd0';
  margin-left: -1.9091796875em;
  color: rgb(3, 75, 80);
}
.bwicon-payment_method_trustly_bank_transfer_large .path15:before {
  content: '\ecd1';
  margin-left: -1.9091796875em;
  color: rgb(3, 75, 80);
}
.bwicon-payment_method_trustly_bank_transfer_large .path16:before {
  content: '\ecd2';
  margin-left: -1.9091796875em;
  color: rgb(137, 137, 137);
}
.bwicon-payment_method_trustly_bank_transfer_large .path17:before {
  content: '\ecd3';
  margin-left: -1.9091796875em;
  color: rgb(137, 137, 137);
}
.bwicon-payment_method_trustly_bank_transfer_large .path18:before {
  content: '\ecd4';
  margin-left: -1.9091796875em;
  color: rgb(251, 252, 252);
}
.bwicon-payment_method_trustly_bank_transfer_large .path19:before {
  content: '\ecd5';
  margin-left: -1.9091796875em;
  color: rgb(251, 252, 252);
}
.bwicon-payment_method_trustly_bank_transfer_large .path20:before {
  content: '\ecd6';
  margin-left: -1.9091796875em;
  color: rgb(251, 252, 252);
}
.bwicon-payment_method_trustly_bank_transfer_large .path21:before {
  content: '\ecd7';
  margin-left: -1.9091796875em;
  color: rgb(153, 153, 153);
}
.bwicon-payment_method_trustly_bank_transfer_large .path22:before {
  content: '\ecd8';
  margin-left: -1.9091796875em;
  color: rgb(4, 116, 182);
}
.bwicon-payment_method_trustly_bank_transfer_large .path23:before {
  content: '\ecd9';
  margin-left: -1.9091796875em;
  color: rgb(4, 116, 182);
}
.bwicon-payment_method_trustly_bank_transfer_large .path24:before {
  content: '\ecda';
  margin-left: -1.9091796875em;
  color: rgb(4, 116, 182);
}
.bwicon-payment_method_trustly_bank_transfer_large .path25:before {
  content: '\ecdb';
  margin-left: -1.9091796875em;
  color: rgb(4, 116, 182);
}
.bwicon-payment_method_trustly_bank_transfer_large .path26:before {
  content: '\ecdc';
  margin-left: -1.9091796875em;
  color: rgb(226, 29, 57);
}
.bwicon-payment_method_trustly_bank_transfer_large .path27:before {
  content: '\ecdd';
  margin-left: -1.9091796875em;
  color: rgb(34, 45, 101);
}
.bwicon-payment_method_trustly_bank_transfer_large .path28:before {
  content: '\ecde';
  margin-left: -1.9091796875em;
  color: rgb(226, 29, 57);
}
.bwicon-payment_method_trustly_bank_transfer_large .path29:before {
  content: '\ecdf';
  margin-left: -1.9091796875em;
  color: rgb(34, 45, 101);
}
.bwicon-payment_method_trustly_bank_transfer_large .path30:before {
  content: '\ece0';
  margin-left: -1.9091796875em;
  color: rgb(226, 29, 57);
}
.bwicon-payment_method_trustly_bank_transfer_large .path31:before {
  content: '\ece1';
  margin-left: -1.9091796875em;
  color: rgb(226, 29, 57);
}
.bwicon-payment_method_trustly_bank_transfer_large .path32:before {
  content: '\ece2';
  margin-left: -1.9091796875em;
  color: rgb(0, 71, 133);
}
.bwicon-payment_method_trustly_bank_transfer_large .path33:before {
  content: '\eb64';
  margin-left: -1.9091796875em;
  color: rgb(0, 71, 133);
}
.bwicon-payment_method_trustly_bank_transfer_large .path34:before {
  content: '\eb65';
  margin-left: -1.9091796875em;
  color: rgb(0, 71, 133);
}
.bwicon-payment_method_trustly_bank_transfer_large .path35:before {
  content: '\ebf1';
  margin-left: -1.9091796875em;
  color: rgb(237, 28, 36);
}
.bwicon-payment_method_trustly_bank_transfer_large .path36:before {
  content: '\ec08';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path37:before {
  content: '\ec0d';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path38:before {
  content: '\ec0f';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path39:before {
  content: '\ec33';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path40:before {
  content: '\ec3e';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path41:before {
  content: '\ec43';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path42:before {
  content: '\ec44';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path43:before {
  content: '\ec45';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path44:before {
  content: '\ec46';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path45:before {
  content: '\ec47';
  margin-left: -1.9091796875em;
  color: rgb(245, 128, 35);
}
.bwicon-payment_method_trustly_bank_transfer_large .path46:before {
  content: '\ec48';
  margin-left: -1.9091796875em;
  color: rgb(245, 128, 35);
}
.bwicon-payment_method_trustly_bank_transfer_large .path47:before {
  content: '\ec49';
  margin-left: -1.9091796875em;
  color: rgb(245, 128, 35);
}
.bwicon-payment_method_trustly_bank_transfer_large .path48:before {
  content: '\ec4a';
  margin-left: -1.9091796875em;
  color: rgb(228, 32, 41);
}
.bwicon-payment_method_trustly_bank_transfer_large .path49:before {
  content: '\ec4b';
  margin-left: -1.9091796875em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_trustly_bank_transfer_large .path50:before {
  content: '\ec4c';
  margin-left: -1.9091796875em;
  color: rgb(3, 75, 80);
}
.bwicon-payment_method_trustly_bank_transfer_small .path1:before {
  content: '\ecab';
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_trustly_bank_transfer_small .path2:before {
  content: '\ecac';
  margin-left: -1em;
  color: rgb(3, 75, 80);
}
.bwicon-payment_method_trustly_bank_transfer_small .path3:before {
  content: '\ecad';
  margin-left: -1em;
  color: rgb(3, 75, 80);
}
.bwicon-payment_method_trustly_bank_transfer_small .path4:before {
  content: '\ecae';
  margin-left: -1em;
  color: rgb(137, 137, 137);
}
.bwicon-payment_method_worldpay_card_large .path1:before {
  content: '\ecaf';
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path2:before {
  content: '\ecb0';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path3:before {
  content: '\ecb1';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path4:before {
  content: '\ecb2';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path5:before {
  content: '\ecb3';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path6:before {
  content: '\ecb4';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path7:before {
  content: '\ec84';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path8:before {
  content: '\ec85';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path9:before {
  content: '\ec86';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path10:before {
  content: '\ec87';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path11:before {
  content: '\ec88';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path12:before {
  content: '\ec89';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path13:before {
  content: '\ec8a';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path14:before {
  content: '\ec8b';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path15:before {
  content: '\ec8c';
  margin-left: -2.4052734375em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_worldpay_card_large .path16:before {
  content: '\ec8d';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path17:before {
  content: '\ec8e';
  margin-left: -2.4052734375em;
  color: rgb(20, 52, 203);
}
.bwicon-payment_method_worldpay_card_large .path18:before {
  content: '\ec8f';
  margin-left: -2.4052734375em;
  color: rgb(255, 255, 255);
}
.bwicon-payment_method_worldpay_card_large .path19:before {
  content: '\eb61';
  margin-left: -2.4052734375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_large .path20:before {
  content: '\eb62';
  margin-left: -2.4052734375em;
  color: rgb(255, 95, 0);
}
.bwicon-payment_method_worldpay_card_large .path21:before {
  content: '\eb63';
  margin-left: -2.4052734375em;
  color: rgb(235, 0, 27);
}
.bwicon-payment_method_worldpay_card_large .path22:before {
  content: '\e901';
  margin-left: -2.4052734375em;
  color: rgb(247, 158, 27);
}
.bwicon-payment_method_worldpay_card_large .path23:before {
  content: '\ec6a';
  margin-left: -2.4052734375em;
  color: rgb(247, 158, 27);
}
.bwicon-payment_method_worldpay_card_small .path1:before {
  content: '\ecb5';
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path2:before {
  content: '\ecb6';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path3:before {
  content: '\ecb7';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path4:before {
  content: '\ecb8';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path5:before {
  content: '\ecb9';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path6:before {
  content: '\ecba';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path7:before {
  content: '\ec90';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path8:before {
  content: '\ec91';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path9:before {
  content: '\ec92';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path10:before {
  content: '\ec93';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path11:before {
  content: '\ec94';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path12:before {
  content: '\ec95';
  margin-left: -1.021484375em;
  color: rgb(0, 0, 0);
}
.bwicon-payment_method_worldpay_card_small .path13:before {
  content: '\ec96';
  margin-left: -1.021484375em;
  color: rgb(56, 78, 161);
}
.bwicon-payment_method_worldpay_card_small .path14:before {
  content: '\ec97';
  margin-left: -1.021484375em;
  color: rgb(242, 97, 34);
}
.bwicon-payment_method_worldpay_card_small .path15:before {
  content: '\ec98';
  margin-left: -1.021484375em;
  color: rgb(234, 29, 37);
}
.bwicon-payment_method_worldpay_card_small .path16:before {
  content: '\ec99';
  margin-left: -1.021484375em;
  color: rgb(246, 158, 30);
}
.bwicon-payment_method_worldpay_card_small .path17:before {
  content: '\ec9a';
  margin-left: -1.021484375em;
  color: rgb(246, 158, 30);
}
.bwicon-payment_method_worldpay_card_small .path18:before {
  content: '\ec9b';
  margin-left: -1.021484375em;
  color: rgb(246, 158, 30);
}
.bwicon-worldpay:before {
  content: '\ec53';
}
.bwicon-trustly:before {
  content: '\ec54';
}
.bwicon-jack_side_menu .path1:before {
  content: '\ecbb';
  color: rgb(0, 0, 0);
}
.bwicon-jack_side_menu .path2:before {
  content: '\ecbc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-jack_side_menu .path3:before {
  content: '\ecbd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-jack-thistledown:before {
  content: '\ec73';
}
.bwicon-icon-bw-logo:before {
  content: '\ec51';
}
.bwicon-transaction_piggy_bank:before {
  content: '\ec40';
}
.bwicon-icon-statistics-settings:before {
  content: '\ed00';
}
.bwicon-transaction_adjust:before {
  content: '\ec24';
}
.bwicon-transaction_bet_void:before {
  content: '\ec25';
}
.bwicon-transaction_bet:before {
  content: '\ec26';
}
.bwicon-transaction_bonus_clock:before {
  content: '\ec27';
}
.bwicon-transaction_bonus:before {
  content: '\ec28';
}
.bwicon-transaction_deposit:before {
  content: '\ec29';
}
.bwicon-transaction_fee:before {
  content: '\ec2a';
}
.bwicon-transaction_unknown:before {
  content: '\ec2b';
}
.bwicon-transaction_withdrawal_cancel:before {
  content: '\ec2c';
}
.bwicon-transaction_withdrawal:before {
  content: '\ec2d';
}
.bwicon-icon-social-linkedin:before {
  content: '\ec64';
}
.bwicon-icon-social-twitter-filled:before {
  content: '\ec52';
}
.bwicon-icon-social-instagram-filled:before {
  content: '\ec42';
}
.bwicon-icon-social-facebook:before {
  content: '\ec3f';
}
.bwicon-icon-social-youtube:before {
  content: '\ec41';
}
.bwicon-icon-social-twitter:before {
  content: '\ec3c';
}
.bwicon-icon-social-instagram:before {
  content: '\ec3d';
}
.bwicon-icon-headset:before {
  content: '\ec55';
}
.bwicon-icon-class-call-of-duty:before {
  content: '\e914';
}
.bwicon-icon-class-cs-go:before {
  content: '\e915';
}
.bwicon-icon-class-dota2:before {
  content: '\e916';
}
.bwicon-icon-class-fifa:before {
  content: '\e917';
}
.bwicon-icon-class-hearthstone:before {
  content: '\e918';
}
.bwicon-icon-class-heroes-of-the-storm:before {
  content: '\e919';
}
.bwicon-icon-class-lol:before {
  content: '\e91a';
}
.bwicon-icon-class-overwatch:before {
  content: '\e91b';
}
.bwicon-icon-class-starcraft:before {
  content: '\e91c';
}
.bwicon-icon-class-street-fighter:before {
  content: '\e91d';
}
.bwicon-icon-category-slots:before {
  content: '\ebf0';
}
.bwicon-icon-category-tv:before {
  content: '\ebee';
}
.bwicon-icon-category-bingo:before {
  content: '\ebef';
}
.bwicon-icon-category-netball:before {
  content: '\ec59';
}
.bwicon-icon-category-formula-1:before {
  content: '\ec58';
}
.bwicon-icon-navigation-promotions:before {
  content: '\ebed';
}
.bwicon-icon-category-olympic-games:before {
  content: '\ebdc';
}
.bwicon-icon-category-surfing:before {
  content: '\ebdd';
}
.bwicon-icon-category-ufc-mma:before {
  content: '\ebde';
}
.bwicon-icon-category-winter-olympic-games:before {
  content: '\ebdf';
}
.bwicon-icon-category-winter-sports:before {
  content: '\ebe0';
}
.bwicon-icon-category-american-football:before {
  content: '\e91e';
}
.bwicon-icon-category-archery:before {
  content: '\ec4d';
}
.bwicon-icon-category-athletics:before {
  content: '\e920';
}
.bwicon-icon-category-australian-rules:before {
  content: '\e921';
}
.bwicon-icon-category-backgammon:before {
  content: '\e922';
}
.bwicon-icon-category-badminton:before {
  content: '\e923';
}
.bwicon-icon-category-bandy:before {
  content: '\eb60';
}
.bwicon-icon-category-baseball:before {
  content: '\e925';
}
.bwicon-icon-category-basketball:before {
  content: '\e926';
}
.bwicon-icon-category-billard:before {
  content: '\e927';
}
.bwicon-icon-category-bowles:before {
  content: '\e928';
}
.bwicon-icon-category-bowling:before {
  content: '\e929';
}
.bwicon-icon-category-boxing:before {
  content: '\e92a';
}
.bwicon-icon-category-cricket:before {
  content: '\e92b';
}
.bwicon-icon-category-curling:before {
  content: '\e92c';
}
.bwicon-icon-category-cycling:before {
  content: '\e92d';
}
.bwicon-icon-category-dancing:before {
  content: '\e92e';
}
.bwicon-icon-category-darts:before {
  content: '\e92f';
}
.bwicon-icon-category-esports:before {
  content: '\e930';
}
.bwicon-icon-category-floorball:before {
  content: '\e931';
}
.bwicon-icon-top-sport:before {
  content: '\e932';
}
.bwicon-icon-category-football:before {
  content: '\e932';
}
.bwicon-icon-category-futsal:before {
  content: '\e932';
}
.bwicon-icon-category-trotting:before {
  content: '\e933';
}
.bwicon-icon-category-golf:before {
  content: '\e934';
}
.bwicon-icon-category-greyhound:before {
  content: '\e935';
}
.bwicon-icon-category-handball:before {
  content: '\e936';
}
.bwicon-icon-category-ice-hockey:before {
  content: '\e937';
}
.bwicon-icon-category-kayak:before {
  content: '\e938';
}
.bwicon-icon-category-langrend:before {
  content: '\e939';
}
.bwicon-icon-category-motorsports:before {
  content: '\e93a';
}
.bwicon-icon-category-poker:before {
  content: '\e93b';
}
.bwicon-icon-category-politics:before {
  content: '\e93c';
}
.bwicon-icon-category-rugby:before {
  content: '\e93d';
}
.bwicon-icon-category-rugby-union:before {
  content: '\e93d';
}
.bwicon-icon-category-rugby-union-league:before {
  content: '\e93d';
}
.bwicon-icon-category-rugby-league:before {
  content: '\e93d';
}
.bwicon-icon-category-sailing:before {
  content: '\e93e';
}
.bwicon-icon-category-chess:before {
  content: '\e93f';
}
.bwicon-icon-category-skihop:before {
  content: '\e940';
}
.bwicon-icon-category-skiing:before {
  content: '\e941';
}
.bwicon-icon-category-skiskydning:before {
  content: '\e942';
}
.bwicon-icon-category-snooker:before {
  content: '\e943';
}
.bwicon-icon-category-speedway:before {
  content: '\e944';
}
.bwicon-icon-category-squash:before {
  content: '\e945';
}
.bwicon-icon-category-swimming:before {
  content: '\e946';
}
.bwicon-icon-category-table-tennis:before {
  content: '\e947';
}
.bwicon-icon-category-tennis:before {
  content: '\e948';
}
.bwicon-icon-category-trav:before {
  content: '\e949';
}
.bwicon-icon-category-triathlon:before {
  content: '\e94a';
}
.bwicon-icon-category-udspring:before {
  content: '\e94b';
}
.bwicon-icon-category-tv---novelty:before {
  content: '\e94c';
}
.bwicon-icon-category-volleyball:before {
  content: '\e94d';
}
.bwicon-icon-category-water-polo:before {
  content: '\e94e';
}
.bwicon-icon-category-wwe-pro-wrestling:before {
  content: '\e94f';
}
.bwicon-icon-class-neutral .path1:before {
  content: '\ece8';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-neutral .path2:before {
  content: '\ecec';
  margin-left: -1em;
  color: rgb(245, 245, 245);
}
.bwicon-icon-class-neutral .path3:before {
  content: '\eced';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.08;
}
.bwicon-icon-class-great-britain .path1:before {
  content: '\ece7';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-great-britain .path2:before {
  content: '\ecee';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path3:before {
  content: '\ecef';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path4:before {
  content: '\ecf0';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path5:before {
  content: '\ecf1';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path6:before {
  content: '\ecf2';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path7:before {
  content: '\ecf3';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path8:before {
  content: '\ecf4';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path9:before {
  content: '\ecf5';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-great-britain .path10:before {
  content: '\ecf6';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-great-britain .path11:before {
  content: '\ecf7';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-great-britain .path12:before {
  content: '\ecf8';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-great-britain .path13:before {
  content: '\ecf9';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-great-britain .path14:before {
  content: '\ed01';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-great-britain .path15:before {
  content: '\ed02';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.04;
}
.bwicon-icon-class-el-salvador .path1:before {
  content: '\ebf2';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-el-salvador .path2:before {
  content: '\ebf3';
  margin-left: -1em;
  color: rgb(4, 82, 180);
}
.bwicon-icon-class-el-salvador .path3:before {
  content: '\ebf4';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-el-salvador .path4:before {
  content: '\ebf5';
  margin-left: -1em;
  color: rgb(110, 166, 68);
}
.bwicon-icon-class-el-salvador .path5:before {
  content: '\ebf6';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-malawi .path1:before {
  content: '\ebe6';
  color: rgb(223, 0, 23);
}
.bwicon-icon-class-malawi .path2:before {
  content: '\ebe7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-malawi .path3:before {
  content: '\ebe8';
  margin-left: -1em;
  color: rgb(223, 0, 23);
}
.bwicon-icon-class-malawi .path4:before {
  content: '\ebe9';
  margin-left: -1em;
  color: rgb(0, 162, 27);
}
.bwicon-icon-class-ivory-coast .path1:before {
  content: '\ebea';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-ivory-coast .path2:before {
  content: '\ebeb';
  margin-left: -1em;
  color: rgb(251, 129, 0);
}
.bwicon-icon-class-ivory-coast .path3:before {
  content: '\ebec';
  margin-left: -1em;
  color: rgb(2, 136, 81);
}
.bwicon-icon-class-africa .path1:before {
  content: '\e902';
  color: rgb(218, 202, 176);
}
.bwicon-icon-class-africa .path2:before {
  content: '\e950';
  margin-left: -1em;
  color: rgb(74, 97, 62);
}
.bwicon-icon-class-albania .path1:before {
  content: '\e903';
  color: rgb(228, 31, 32);
}
.bwicon-icon-class-albania .path2:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-algeria .path1:before {
  content: '\e951';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-algeria .path2:before {
  content: '\e952';
  margin-left: -1em;
  color: rgb(0, 98, 51);
}
.bwicon-icon-class-algeria .path3:before {
  content: '\e953';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-american-samoa .path1:before {
  content: '\e954';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-american-samoa .path2:before {
  content: '\e955';
  margin-left: -1em;
  color: rgb(6, 0, 102);
}
.bwicon-icon-class-american-samoa .path3:before {
  content: '\e956';
  margin-left: -1em;
  color: rgb(188, 13, 29);
}
.bwicon-icon-class-american-samoa .path4:before {
  content: '\e957';
  margin-left: -1em;
  color: rgb(129, 63, 26);
}
.bwicon-icon-class-american-samoa .path5:before {
  content: '\e958';
  margin-left: -1em;
  color: rgb(246, 184, 2);
}
.bwicon-icon-class-andorra .path1:before {
  content: '\e959';
  color: rgb(254, 223, 0);
}
.bwicon-icon-class-andorra .path2:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(208, 16, 58);
}
.bwicon-icon-class-andorra .path3:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(9, 25, 168);
}
.bwicon-icon-class-andorra .path4:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(193, 172, 122);
}
.bwicon-icon-class-angola .path1:before {
  content: '\e95d';
  color: rgb(204, 10, 47);
}
.bwicon-icon-class-angola .path2:before {
  content: '\e95e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-angola .path3:before {
  content: '\e95f';
  margin-left: -1em;
  color: rgb(255, 204, 2);
}
.bwicon-icon-class-antigua-and-barbuda .path1:before {
  content: '\e960';
  color: rgb(0, 114, 198);
}
.bwicon-icon-class-antigua-and-barbuda .path2:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-antigua-and-barbuda .path3:before {
  content: '\e962';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-antigua-and-barbuda .path4:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-antigua-and-barbuda .path5:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-argentina .path1:before {
  content: '\e965';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-argentina .path2:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(116, 172, 223);
}
.bwicon-icon-class-argentina .path3:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(245, 179, 16);
}
.bwicon-icon-class-armenia .path1:before {
  content: '\e968';
  color: rgb(0, 51, 160);
}
.bwicon-icon-class-armenia .path2:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(242, 168, 0);
}
.bwicon-icon-class-armenia .path3:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(217, 0, 19);
}
.bwicon-icon-class-aruba .path1:before {
  content: '\e96b';
  color: rgb(248, 217, 19);
}
.bwicon-icon-class-aruba .path2:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(47, 153, 204);
}
.bwicon-icon-class-aruba .path3:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-aruba .path4:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(237, 42, 44);
}
.bwicon-icon-class-asia .path1:before {
  content: '\e904';
  color: rgb(220, 90, 90);
}
.bwicon-icon-class-asia .path2:before {
  content: '\ecfa';
  margin-left: -1em;
  color: rgb(233, 234, 69);
}
.bwicon-icon-class-australia .path1:before {
  content: '\e96f';
  color: rgb(0, 33, 105);
}
.bwicon-icon-class-australia .path2:before {
  content: '\e970';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-australia .path3:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(228, 0, 44);
}
.bwicon-icon-class-austria .path1:before {
  content: '\e972';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-austria .path2:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-azerbaijan .path1:before {
  content: '\e974';
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-azerbaijan .path2:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(62, 156, 53);
}
.bwicon-icon-class-azerbaijan .path3:before {
  content: '\e976';
  margin-left: -1em;
  color: rgb(0, 185, 227);
}
.bwicon-icon-class-azerbaijan .path4:before {
  content: '\e977';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-bahamas .path1:before {
  content: '\e978';
  color: rgb(255, 199, 45);
}
.bwicon-icon-class-bahamas .path2:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(1, 119, 139);
}
.bwicon-icon-class-bahamas .path3:before {
  content: '\e97a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-bahrain .path1:before {
  content: '\e97b';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-bahrain .path2:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-bangladesh .path1:before {
  content: '\e97d';
  color: rgb(1, 106, 78);
}
.bwicon-icon-class-bangladesh .path2:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(244, 43, 65);
}
.bwicon-icon-class-barbados .path1:before {
  content: '\e97f';
  color: rgb(255, 199, 38);
}
.bwicon-icon-class-barbados .path2:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(0, 38, 127);
}
.bwicon-icon-class-barbados .path3:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-belarus .path1:before {
  content: '\e982';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-belarus .path2:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(74, 166, 86);
}
.bwicon-icon-class-belarus .path3:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(200, 48, 62);
}
.bwicon-icon-class-belgium .path1:before {
  content: '\e985';
  color: rgb(250, 224, 66);
}
.bwicon-icon-class-belgium .path2:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-belgium .path3:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-belize .path1:before {
  content: '\e988';
  color: rgb(1, 63, 135);
}
.bwicon-icon-class-belize .path2:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-belize .path3:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-belize .path4:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(39, 148, 1);
}
.bwicon-icon-class-belize .path5:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(255, 216, 59);
}
.bwicon-icon-class-benin .path1:before {
  content: '\e98e';
  color: rgb(232, 17, 45);
}
.bwicon-icon-class-benin .path2:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-benin .path3:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(0, 135, 81);
}
.bwicon-icon-class-bermuda .path1:before {
  content: '\e991';
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-bermuda .path2:before {
  content: '\e992';
  margin-left: -1em;
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-bermuda .path3:before {
  content: '\e993';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-bermuda .path4:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(47, 143, 35);
}
.bwicon-icon-class-bermuda .path5:before {
  content: '\e995';
  margin-left: -1em;
  color: rgb(51, 138, 243);
}
.bwicon-icon-class-bermuda .path6:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-bhutan .path1:before {
  content: '\e997';
  color: rgb(255, 213, 31);
}
.bwicon-icon-class-bhutan .path2:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(255, 78, 19);
}
.bwicon-icon-class-bhutan .path3:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-bolivia .path1:before {
  content: '\e99a';
  color: rgb(249, 227, 2);
}
.bwicon-icon-class-bolivia .path2:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(3, 121, 52);
}
.bwicon-icon-class-bolivia .path3:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(213, 44, 30);
}
.bwicon-icon-class-bosnia .path1:before {
  content: '\e99d';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-bosnia .path2:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(254, 203, 0);
}
.bwicon-icon-class-bosnia .path3:before {
  content: '\e99f';
  margin-left: -1em;
  color: rgb(0, 35, 149);
}
.bwicon-icon-class-bosnia-and-herzegovina .path1:before {
  content: '\ed0a';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-bosnia-and-herzegovina .path2:before {
  content: '\ed0b';
  margin-left: -1em;
  color: rgb(254, 203, 0);
}
.bwicon-icon-class-bosnia-and-herzegovina .path3:before {
  content: '\ed0c';
  margin-left: -1em;
  color: rgb(0, 35, 149);
}
.bwicon-icon-class-botswana .path1:before {
  content: '\e9a0';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-botswana .path2:before {
  content: '\e9a1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-botswana .path3:before {
  content: '\e9a2';
  margin-left: -1em;
  color: rgb(117, 170, 219);
}
.bwicon-icon-class-brazil .path1:before {
  content: '\e9a3';
  color: rgb(0, 155, 58);
}
.bwicon-icon-class-brazil .path2:before {
  content: '\e9a4';
  margin-left: -1em;
  color: rgb(254, 223, 0);
}
.bwicon-icon-class-brazil .path3:before {
  content: '\e9a5';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-brazil .path4:before {
  content: '\e9a6';
  margin-left: -1em;
  color: rgb(0, 39, 118);
}
.bwicon-icon-class-british-virgin-islands .path1:before {
  content: '\e9a7';
  color: rgb(0, 31, 126);
}
.bwicon-icon-class-british-virgin-islands .path2:before {
  content: '\e9a8';
  margin-left: -1em;
  color: rgb(0, 97, 36);
}
.bwicon-icon-class-british-virgin-islands .path3:before {
  content: '\e9a9';
  margin-left: -1em;
  color: rgb(246, 199, 2);
}
.bwicon-icon-class-british-virgin-islands .path4:before {
  content: '\e9aa';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-british-virgin-islands .path5:before {
  content: '\e9ab';
  margin-left: -1em;
  color: rgb(208, 11, 39);
}
.bwicon-icon-class-brunei .path1:before {
  content: '\e9ac';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-brunei .path2:before {
  content: '\e9ad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-brunei .path3:before {
  content: '\e9ae';
  margin-left: -1em;
  color: rgb(247, 224, 24);
}
.bwicon-icon-class-brunei .path4:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(211, 15, 38);
}
.bwicon-icon-class-bulgaria .path1:before {
  content: '\e9b0';
  color: rgb(1, 150, 110);
}
.bwicon-icon-class-bulgaria .path2:before {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(214, 39, 18);
}
.bwicon-icon-class-bulgaria .path3:before {
  content: '\e9b2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-burundi .path1:before {
  content: '\e9b3';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-burundi .path2:before {
  content: '\e9b4';
  margin-left: -1em;
  color: rgb(30, 181, 58);
}
.bwicon-icon-class-burundi .path3:before {
  content: '\e9b5';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-cambodia .path1:before {
  content: '\e9b6';
  color: rgb(224, 0, 37);
}
.bwicon-icon-class-cambodia .path2:before {
  content: '\e9b7';
  margin-left: -1em;
  color: rgb(4, 46, 161);
}
.bwicon-icon-class-cambodia .path3:before {
  content: '\e9b8';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-cameroon .path1:before {
  content: '\e9b9';
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-cameroon .path2:before {
  content: '\e9ba';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-cameroon .path3:before {
  content: '\e9bb';
  margin-left: -1em;
  color: rgb(0, 122, 94);
}
.bwicon-icon-class-canada .path1:before {
  content: '\e9bc';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-canada .path2:before {
  content: '\e9bd';
  margin-left: -1em;
  color: rgb(213, 44, 30);
}
.bwicon-icon-class-cape-verde .path1:before {
  content: '\e9be';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-cape-verde .path2:before {
  content: '\e9bf';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-cape-verde .path3:before {
  content: '\e9c0';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-cape-verde .path4:before {
  content: '\e9c1';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-cayman-islands .path1:before {
  content: '\e9c2';
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-cayman-islands .path2:before {
  content: '\e9c3';
  margin-left: -1em;
  color: rgb(0, 149, 50);
}
.bwicon-icon-class-cayman-islands .path3:before {
  content: '\e9c4';
  margin-left: -1em;
  color: rgb(252, 234, 131);
}
.bwicon-icon-class-cayman-islands .path4:before {
  content: '\e9c5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-cayman-islands .path5:before {
  content: '\e9c6';
  margin-left: -1em;
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-champions-league .path1:before {
  content: '\e90a';
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-champions-league .path2:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-chile .path1:before {
  content: '\e9c7';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-chile .path2:before {
  content: '\e9c8';
  margin-left: -1em;
  color: rgb(213, 44, 30);
}
.bwicon-icon-class-chile .path3:before {
  content: '\e9c9';
  margin-left: -1em;
  color: rgb(0, 57, 166);
}
.bwicon-icon-class-china .path1:before {
  content: '\e9ca';
  color: rgb(222, 41, 15);
}
.bwicon-icon-class-china .path2:before {
  content: '\e9cb';
  margin-left: -1em;
  color: rgb(255, 222, 2);
}
.bwicon-icon-class-colombia .path1:before {
  content: '\e9cc';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-colombia .path2:before {
  content: '\e9cd';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-colombia .path3:before {
  content: '\e9ce';
  margin-left: -1em;
  color: rgb(0, 55, 147);
}
.bwicon-icon-class-cook-islands .path1:before {
  content: '\e9cf';
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-cook-islands .path2:before {
  content: '\e9d0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-cook-islands .path3:before {
  content: '\e9d1';
  margin-left: -1em;
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-copa-libertadores .path1:before {
  content: '\e90c';
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-copa-libertadores .path2:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-copa-sudamericana .path1:before {
  content: '\ec6d';
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-copa-sudamericana .path2:before {
  content: '\ec6e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-costa-rica .path1:before {
  content: '\e9d2';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-costa-rica .path2:before {
  content: '\e9d3';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-costa-rica .path3:before {
  content: '\e9d4';
  margin-left: -1em;
  color: rgb(1, 42, 127);
}
.bwicon-icon-class-croatia .path1:before {
  content: '\e9d5';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-croatia .path2:before {
  content: '\e9d6';
  margin-left: -1em;
  color: rgb(23, 23, 150);
}
.bwicon-icon-class-croatia .path3:before {
  content: '\e9d7';
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-croatia .path4:before {
  content: '\e9d8';
  margin-left: -1em;
  color: rgb(0, 144, 222);
}
.bwicon-icon-class-croatia .path5:before {
  content: '\e9d9';
  margin-left: -1em;
  color: rgb(23, 23, 150);
}
.bwicon-icon-class-cuba .path1:before {
  content: '\e9da';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-cuba .path2:before {
  content: '\e9db';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-cuba .path3:before {
  content: '\e9dc';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-cuba .path4:before {
  content: '\e9dd';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-curacao .path1:before {
  content: '\e9de';
  color: rgb(249, 232, 18);
}
.bwicon-icon-class-curacao .path2:before {
  content: '\e9df';
  margin-left: -1em;
  color: rgb(1, 42, 127);
}
.bwicon-icon-class-curacao .path3:before {
  content: '\e9e0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-cyprus .path1:before {
  content: '\e9e1';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-cyprus .path2:before {
  content: '\e9e2';
  margin-left: -1em;
  color: rgb(97, 108, 75);
}
.bwicon-icon-class-cyprus .path3:before {
  content: '\e9e3';
  margin-left: -1em;
  color: rgb(212, 121, 0);
}
.bwicon-icon-class-czech-republic .path1:before {
  content: '\e9e4';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-czech-republic .path2:before {
  content: '\e9e5';
  margin-left: -1em;
  color: rgb(215, 19, 25);
}
.bwicon-icon-class-czech-republic .path3:before {
  content: '\e9e6';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-czech-republic .path4:before {
  content: '\e9e7';
  margin-left: -1em;
  color: rgb(15, 69, 126);
}
.bwicon-icon-class-democratic-republic-of-congo .path1:before {
  content: '\e9e8';
  color: rgb(207, 6, 28);
}
.bwicon-icon-class-democratic-republic-of-congo .path2:before {
  content: '\e9e9';
  margin-left: -1em;
  color: rgb(0, 128, 255);
}
.bwicon-icon-class-democratic-republic-of-congo .path3:before {
  content: '\e9ea';
  margin-left: -1em;
  color: rgb(247, 215, 18);
}
.bwicon-icon-class-denmark .path1:before {
  content: '\e9eb';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-denmark .path2:before {
  content: '\e9ec';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-djibouti .path1:before {
  content: '\e9ed';
  color: rgb(106, 178, 231);
}
.bwicon-icon-class-djibouti .path2:before {
  content: '\e9ee';
  margin-left: -1em;
  color: rgb(21, 173, 44);
}
.bwicon-icon-class-djibouti .path3:before {
  content: '\e9ef';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-djibouti .path4:before {
  content: '\e9f0';
  margin-left: -1em;
  color: rgb(215, 19, 25);
}
.bwicon-icon-class-dominican-republic .path1:before {
  content: '\e9f1';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-dominican-republic .path2:before {
  content: '\e9f2';
  margin-left: -1em;
  color: rgb(0, 45, 97);
}
.bwicon-icon-class-dominican-republic .path3:before {
  content: '\e9f3';
  margin-left: -1em;
  color: rgb(40, 129, 51);
}
.bwicon-icon-class-dominican-republic .path4:before {
  content: '\e9f4';
  margin-left: -1em;
  color: rgb(0, 45, 97);
}
.bwicon-icon-class-dominican-republic .path5:before {
  content: '\e9f5';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-ecuador .path1:before {
  content: '\e9f6';
  color: rgb(255, 222, 2);
}
.bwicon-icon-class-ecuador .path2:before {
  content: '\e9f7';
  margin-left: -1em;
  color: rgb(239, 22, 30);
}
.bwicon-icon-class-ecuador .path3:before {
  content: '\e9f8';
  margin-left: -1em;
  color: rgb(2, 77, 163);
}
.bwicon-icon-class-ecuador .path4:before {
  content: '\e9f9';
  margin-left: -1em;
  color: rgb(127, 205, 230);
}
.bwicon-icon-class-ecuador .path5:before {
  content: '\e9fa';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-egypt .path1:before {
  content: '\e9fb';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-egypt .path2:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(224, 0, 23);
}
.bwicon-icon-class-egypt .path3:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-egypt .path4:before {
  content: '\e9fe';
  margin-left: -1em;
  color: rgb(210, 169, 75);
}
.bwicon-icon-class-england .path1:before {
  content: '\e9ff';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-england .path2:before {
  content: '\ea00';
  margin-left: -1em;
  color: rgb(206, 16, 36);
}
.bwicon-icon-class-estonia .path1:before {
  content: '\ea01';
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-estonia .path2:before {
  content: '\ea02';
  margin-left: -1em;
  color: rgb(1, 114, 206);
}
.bwicon-icon-class-estonia .path3:before {
  content: '\ea03';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-ethiopia .path1:before {
  content: '\ea04';
  color: rgb(252, 221, 12);
}
.bwicon-icon-class-ethiopia .path2:before {
  content: '\ea05';
  margin-left: -1em;
  color: rgb(218, 17, 27);
}
.bwicon-icon-class-ethiopia .path3:before {
  content: '\ea06';
  margin-left: -1em;
  color: rgb(9, 137, 48);
}
.bwicon-icon-class-ethiopia .path4:before {
  content: '\ea07';
  margin-left: -1em;
  color: rgb(15, 71, 175);
}
.bwicon-icon-class-ethiopia .path5:before {
  content: '\ea08';
  margin-left: -1em;
  color: rgb(252, 221, 12);
}
.bwicon-icon-class-europa-league .path1:before {
  content: '\e90e';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-europa-league .path2:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-europe .path1:before {
  content: '\e905';
  color: rgb(0, 51, 153);
}
.bwicon-icon-class-europe .path2:before {
  content: '\ecff';
  margin-left: -1em;
  color: rgb(255, 204, 0);
}
.bwicon-icon-class-faroe-islands .path1:before {
  content: '\ea09';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-faroe-islands .path2:before {
  content: '\ea0a';
  margin-left: -1em;
  color: rgb(2, 101, 189);
}
.bwicon-icon-class-faroe-islands .path3:before {
  content: '\ea0b';
  margin-left: -1em;
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-fiji .path1:before {
  content: '\ea0c';
  color: rgb(104, 189, 227);
}
.bwicon-icon-class-fiji .path2:before {
  content: '\ea0d';
  margin-left: -1em;
  color: rgb(1, 39, 104);
}
.bwicon-icon-class-fiji .path3:before {
  content: '\ea0e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-fiji .path4:before {
  content: '\ea0f';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-finland .path1:before {
  content: '\ea10';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-finland .path2:before {
  content: '\ea11';
  margin-left: -1em;
  color: rgb(0, 53, 128);
}
.bwicon-icon-class-france .path1:before {
  content: '\ea12';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-france .path2:before {
  content: '\ea13';
  margin-left: -1em;
  color: rgb(236, 42, 57);
}
.bwicon-icon-class-france .path3:before {
  content: '\ea14';
  margin-left: -1em;
  color: rgb(0, 35, 148);
}
.bwicon-icon-class-french-polynesia .path1:before {
  content: '\ea15';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-french-polynesia .path2:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(255, 154, 12);
}
.bwicon-icon-class-french-polynesia .path3:before {
  content: '\ea17';
  margin-left: -1em;
  color: rgb(2, 58, 154);
}
.bwicon-icon-class-french-polynesia .path4:before {
  content: '\ea18';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-gabon .path1:before {
  content: '\ea19';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-gabon .path2:before {
  content: '\ea1a';
  margin-left: -1em;
  color: rgb(58, 117, 196);
}
.bwicon-icon-class-gabon .path3:before {
  content: '\ea1b';
  margin-left: -1em;
  color: rgb(0, 158, 95);
}
.bwicon-icon-class-gambia .path1:before {
  content: '\ea1c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-gambia .path2:before {
  content: '\ea1d';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-gambia .path3:before {
  content: '\ea1e';
  margin-left: -1em;
  color: rgb(58, 119, 40);
}
.bwicon-icon-class-gambia .path4:before {
  content: '\ea1f';
  margin-left: -1em;
  color: rgb(12, 29, 140);
}
.bwicon-icon-class-georgia .path1:before {
  content: '\ea20';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-georgia .path2:before {
  content: '\ea21';
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-germany .path1:before {
  content: '\ea22';
  color: rgb(255, 206, 0);
}
.bwicon-icon-class-germany .path2:before {
  content: '\ea23';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-germany .path3:before {
  content: '\ea24';
  margin-left: -1em;
  color: rgb(221, 0, 0);
}
.bwicon-icon-class-ghana .path1:before {
  content: '\ea25';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-ghana .path2:before {
  content: '\ea26';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-ghana .path3:before {
  content: '\ea27';
  margin-left: -1em;
  color: rgb(1, 107, 63);
}
.bwicon-icon-class-ghana .path4:before {
  content: '\ea28';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-gibraltar .path1:before {
  content: '\ea29';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-gibraltar .path2:before {
  content: '\ea2a';
  margin-left: -1em;
  color: rgb(218, 2, 11);
}
.bwicon-icon-class-gibraltar .path3:before {
  content: '\ea2b';
  margin-left: -1em;
  color: rgb(228, 208, 14);
}
.bwicon-icon-class-greece .path1:before {
  content: '\ea2c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-greece .path2:before {
  content: '\ea2d';
  margin-left: -1em;
  color: rgb(13, 94, 175);
}
.bwicon-icon-class-grenada .path1:before {
  content: '\ea2e';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-grenada .path2:before {
  content: '\ea2f';
  margin-left: -1em;
  color: rgb(0, 122, 94);
}
.bwicon-icon-class-grenada .path3:before {
  content: '\ea30';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-guadeloupe .path1:before {
  content: '\ea31';
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-guadeloupe .path2:before {
  content: '\ea32';
  margin-left: -1em;
  color: rgb(0, 36, 136);
}
.bwicon-icon-class-guadeloupe .path3:before {
  content: '\ea33';
  margin-left: -1em;
  color: rgb(1, 127, 0);
}
.bwicon-icon-class-guadeloupe .path4:before {
  content: '\ea34';
  margin-left: -1em;
  color: rgb(252, 221, 12);
}
.bwicon-icon-class-guam .path1:before {
  content: '\ea35';
  color: rgb(0, 41, 123);
}
.bwicon-icon-class-guam .path2:before {
  content: '\ea36';
  margin-left: -1em;
  color: rgb(146, 219, 252);
}
.bwicon-icon-class-guam .path3:before {
  content: '\ea37';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-guam .path4:before {
  content: '\ea38';
  margin-left: -1em;
  color: rgb(33, 152, 56);
}
.bwicon-icon-class-guam .path5:before {
  content: '\ea39';
  margin-left: -1em;
  color: rgb(137, 92, 54);
}
.bwicon-icon-class-guam .path6:before {
  content: '\ea3a';
  margin-left: -1em;
  color: rgb(198, 33, 57);
}
.bwicon-icon-class-guatemala .path1:before {
  content: '\ea3b';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-guatemala .path2:before {
  content: '\ea3c';
  margin-left: -1em;
  color: rgb(73, 152, 208);
}
.bwicon-icon-class-guatemala .path3:before {
  content: '\ea3d';
  margin-left: -1em;
  color: rgb(201, 205, 206);
}
.bwicon-icon-class-guatemala .path4:before {
  content: '\ea3e';
  margin-left: -1em;
  color: rgb(74, 132, 39);
}
.bwicon-icon-class-guyana .path1:before {
  content: '\ea3f';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-guyana .path2:before {
  content: '\ea40';
  margin-left: -1em;
  color: rgb(2, 158, 73);
}
.bwicon-icon-class-guyana .path3:before {
  content: '\ea41';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-guyana .path4:before {
  content: '\ea42';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-guyana .path5:before {
  content: '\ea43';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-haiti .path1:before {
  content: '\ea44';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-haiti .path2:before {
  content: '\ea45';
  margin-left: -1em;
  color: rgb(226, 171, 27);
}
.bwicon-icon-class-haiti .path3:before {
  content: '\ea46';
  margin-left: -1em;
  color: rgb(39, 105, 11);
}
.bwicon-icon-class-haiti .path4:before {
  content: '\ea47';
  margin-left: -1em;
  color: rgb(3, 32, 159);
}
.bwicon-icon-class-haiti .path5:before {
  content: '\ea48';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-honduras .path1:before {
  content: '\ea49';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-honduras .path2:before {
  content: '\ea4a';
  margin-left: -1em;
  color: rgb(51, 138, 243);
}
.bwicon-icon-class-hong-kong .path1:before {
  content: '\ea4b';
  color: rgb(222, 41, 15);
}
.bwicon-icon-class-hong-kong .path2:before {
  content: '\ea4c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-hungary .path1:before {
  content: '\ea4d';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-hungary .path2:before {
  content: '\ea4e';
  margin-left: -1em;
  color: rgb(205, 42, 62);
}
.bwicon-icon-class-hungary .path3:before {
  content: '\ea4f';
  margin-left: -1em;
  color: rgb(67, 111, 77);
}
.bwicon-icon-class-iceland .path1:before {
  content: '\ea50';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-iceland .path2:before {
  content: '\ea51';
  margin-left: -1em;
  color: rgb(2, 82, 156);
}
.bwicon-icon-class-iceland .path3:before {
  content: '\ea52';
  margin-left: -1em;
  color: rgb(220, 29, 53);
}
.bwicon-icon-class-india .path1:before {
  content: '\ea53';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-india .path2:before {
  content: '\ea54';
  margin-left: -1em;
  color: rgb(255, 153, 51);
}
.bwicon-icon-class-india .path3:before {
  content: '\ea55';
  margin-left: -1em;
  color: rgb(20, 136, 8);
}
.bwicon-icon-class-india .path4:before {
  content: '\ea56';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-indonesia .path1:before {
  content: '\ea57';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-indonesia .path2:before {
  content: '\ea58';
  margin-left: -1em;
  color: rgb(200, 16, 46);
}
.bwicon-icon-class-international .path1:before {
  content: '\e906';
  color: rgb(0, 51, 153);
}
.bwicon-icon-class-international .path2:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.bwicon-icon-class-international .path3:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(149, 231, 187);
}
.bwicon-icon-class-international .path4:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.04;
}
.bwicon-icon-class-iran .path1:before {
  content: '\ea59';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-iran .path2:before {
  content: '\ea5a';
  margin-left: -1em;
  color: rgb(218, 2, 1);
}
.bwicon-icon-class-iran .path3:before {
  content: '\ea5b';
  margin-left: -1em;
  color: rgb(36, 159, 64);
}
.bwicon-icon-class-iraq .path1:before {
  content: '\ea5c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-iraq .path2:before {
  content: '\ea5d';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-iraq .path3:before {
  content: '\ea5e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-iraq .path4:before {
  content: '\ea5f';
  margin-left: -1em;
  color: rgb(1, 122, 61);
}
.bwicon-icon-class-ireland .path1:before {
  content: '\ea60';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-ireland .path2:before {
  content: '\ea61';
  margin-left: -1em;
  color: rgb(255, 136, 62);
}
.bwicon-icon-class-ireland .path3:before {
  content: '\ea62';
  margin-left: -1em;
  color: rgb(23, 155, 98);
}
.bwicon-icon-class-israel .path1:before {
  content: '\ea63';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-israel .path2:before {
  content: '\ea64';
  margin-left: -1em;
  color: rgb(0, 56, 184);
}
.bwicon-icon-class-italy .path1:before {
  content: '\ea65';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-italy .path2:before {
  content: '\ea66';
  margin-left: -1em;
  color: rgb(206, 43, 55);
}
.bwicon-icon-class-italy .path3:before {
  content: '\ea67';
  margin-left: -1em;
  color: rgb(2, 146, 70);
}
.bwicon-icon-class-jamaica .path1:before {
  content: '\ea68';
  color: rgb(250, 220, 8);
}
.bwicon-icon-class-jamaica .path2:before {
  content: '\ea69';
  margin-left: -1em;
  color: rgb(47, 138, 65);
}
.bwicon-icon-class-jamaica .path3:before {
  content: '\ea6a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-japan .path1:before {
  content: '\ea6b';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-japan .path2:before {
  content: '\ea6c';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-japan .path3:before {
  content: '\e900';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.04;
}
.bwicon-icon-class-jordan .path1:before {
  content: '\ea6d';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-jordan .path2:before {
  content: '\ea6e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-jordan .path3:before {
  content: '\ea6f';
  margin-left: -1em;
  color: rgb(1, 122, 61);
}
.bwicon-icon-class-jordan .path4:before {
  content: '\ea70';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-jordan .path5:before {
  content: '\ea71';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-kazakhstan .path1:before {
  content: '\ea72';
  color: rgb(0, 175, 202);
}
.bwicon-icon-class-kazakhstan .path2:before {
  content: '\ea73';
  margin-left: -1em;
  color: rgb(254, 197, 13);
}
.bwicon-icon-class-kenya .path1:before {
  content: '\ea74';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-kenya .path2:before {
  content: '\ea75';
  margin-left: -1em;
  color: rgb(2, 182, 2);
}
.bwicon-icon-class-kenya .path3:before {
  content: '\ea76';
  margin-left: -1em;
  color: rgb(253, 1, 3);
}
.bwicon-icon-class-kenya .path4:before {
  content: '\ea77';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-kosovo .path1:before {
  content: '\ea78';
  color: rgb(36, 74, 165);
}
.bwicon-icon-class-kosovo .path2:before {
  content: '\ea79';
  margin-left: -1em;
  color: rgb(208, 167, 80);
}
.bwicon-icon-class-kosovo .path3:before {
  content: '\ea7a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-kuwait .path1:before {
  content: '\ea7b';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-kuwait .path2:before {
  content: '\ea7c';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-kuwait .path3:before {
  content: '\ea7d';
  margin-left: -1em;
  color: rgb(1, 122, 61);
}
.bwicon-icon-class-kuwait .path4:before {
  content: '\ea7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-kyrgyzstan .path1:before {
  content: '\ea7f';
  color: rgb(232, 17, 45);
}
.bwicon-icon-class-kyrgyzstan .path2:before {
  content: '\ea80';
  margin-left: -1em;
  color: rgb(254, 239, 2);
}
.bwicon-icon-class-laos .path1:before {
  content: '\ea81';
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-laos .path2:before {
  content: '\ea82';
  margin-left: -1em;
  color: rgb(1, 39, 104);
}
.bwicon-icon-class-laos .path3:before {
  content: '\ea83';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-latvia .path1:before {
  content: '\ea84';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-latvia .path2:before {
  content: '\ea85';
  margin-left: -1em;
  color: rgb(158, 48, 57);
}
.bwicon-icon-class-lebanon .path1:before {
  content: '\ea86';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-lebanon .path2:before {
  content: '\ea87';
  margin-left: -1em;
  color: rgb(237, 27, 37);
}
.bwicon-icon-class-lebanon .path3:before {
  content: '\ea88';
  margin-left: -1em;
  color: rgb(0, 166, 81);
}
.bwicon-icon-class-lesotho .path1:before {
  content: '\ea89';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-lesotho .path2:before {
  content: '\ea8a';
  margin-left: -1em;
  color: rgb(0, 149, 67);
}
.bwicon-icon-class-lesotho .path3:before {
  content: '\ea8b';
  margin-left: -1em;
  color: rgb(3, 32, 159);
}
.bwicon-icon-class-lesotho .path4:before {
  content: '\ea8c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-liberia .path1:before {
  content: '\ea8d';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-liberia .path2:before {
  content: '\ea8e';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-liberia .path3:before {
  content: '\ea8f';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-liberia .path4:before {
  content: '\ea90';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-liberia .path5:before {
  content: '\ea91';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-libya .path1:before {
  content: '\ea92';
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-libya .path2:before {
  content: '\ea93';
  margin-left: -1em;
  color: rgb(34, 158, 70);
}
.bwicon-icon-class-libya .path3:before {
  content: '\ea94';
  margin-left: -1em;
  color: rgb(231, 0, 18);
}
.bwicon-icon-class-libya .path4:before {
  content: '\ea95';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-liechtenstein .path1:before {
  content: '\ea96';
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-liechtenstein .path2:before {
  content: '\ea97';
  margin-left: -1em;
  color: rgb(1, 42, 127);
}
.bwicon-icon-class-liechtenstein .path3:before {
  content: '\ea98';
  margin-left: -1em;
  color: rgb(255, 216, 61);
}
.bwicon-icon-class-lithuania .path1:before {
  content: '\ea99';
  color: rgb(0, 106, 68);
}
.bwicon-icon-class-lithuania .path2:before {
  content: '\ea9a';
  margin-left: -1em;
  color: rgb(253, 185, 19);
}
.bwicon-icon-class-lithuania .path3:before {
  content: '\ea9b';
  margin-left: -1em;
  color: rgb(193, 39, 45);
}
.bwicon-icon-class-luxembourg .path1:before {
  content: '\ea9c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-luxembourg .path2:before {
  content: '\ea9d';
  margin-left: -1em;
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-luxembourg .path3:before {
  content: '\ea9e';
  margin-left: -1em;
  color: rgb(0, 161, 222);
}
.bwicon-icon-class-macao .path1:before {
  content: '\ea9f';
  color: rgb(0, 120, 94);
}
.bwicon-icon-class-macao .path2:before {
  content: '\eaa0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-macao .path3:before {
  content: '\eaa1';
  margin-left: -1em;
  color: rgb(241, 206, 24);
}
.bwicon-icon-class-macedonien .path1:before {
  content: '\eaa2';
  color: rgb(209, 2, 0);
}
.bwicon-icon-class-macedonien .path2:before {
  content: '\eaa3';
  margin-left: -1em;
  color: rgb(255, 230, 0);
}
.bwicon-icon-class-north-macedonia .path1:before {
  content: '\ed0d';
  color: rgb(209, 2, 0);
}
.bwicon-icon-class-north-macedonia .path2:before {
  content: '\ed0e';
  margin-left: -1em;
  color: rgb(255, 230, 0);
}
.bwicon-icon-class-malaysia .path1:before {
  content: '\eaa4';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-malaysia .path2:before {
  content: '\eaa5';
  margin-left: -1em;
  color: rgb(221, 29, 42);
}
.bwicon-icon-class-malaysia .path3:before {
  content: '\eaa6';
  margin-left: -1em;
  color: rgb(36, 44, 119);
}
.bwicon-icon-class-malaysia .path4:before {
  content: '\eaa7';
  margin-left: -1em;
  color: rgb(255, 199, 9);
}
.bwicon-icon-class-malta .path1:before {
  content: '\eaa8';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-malta .path2:before {
  content: '\eaa9';
  margin-left: -1em;
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-malta .path3:before {
  content: '\eaaa';
  margin-left: -1em;
  color: rgb(181, 181, 181);
}
.bwicon-icon-class-martinique .path1:before {
  content: '\eaab';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-martinique .path2:before {
  content: '\eaac';
  margin-left: -1em;
  color: rgb(32, 66, 142);
}
.bwicon-icon-class-mauritania .path1:before {
  content: '\eaad';
  color: rgb(0, 101, 45);
}
.bwicon-icon-class-mauritania .path2:before {
  content: '\eaae';
  margin-left: -1em;
  color: rgb(255, 195, 0);
}
.bwicon-icon-class-mauritania .path3:before {
  content: '\eaaf';
  margin-left: -1em;
  color: rgb(223, 0, 56);
}
.bwicon-icon-class-mauritius .path1:before {
  content: '\eab0';
  color: rgb(235, 39, 13);
}
.bwicon-icon-class-mauritius .path2:before {
  content: '\eab1';
  margin-left: -1em;
  color: rgb(247, 183, 23);
}
.bwicon-icon-class-mauritius .path3:before {
  content: '\eab2';
  margin-left: -1em;
  color: rgb(44, 51, 89);
}
.bwicon-icon-class-mauritius .path4:before {
  content: '\eab3';
  margin-left: -1em;
  color: rgb(3, 134, 88);
}
.bwicon-icon-class-mexico .path1:before {
  content: '\eab4';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-mexico .path2:before {
  content: '\eab5';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-mexico .path3:before {
  content: '\eab6';
  margin-left: -1em;
  color: rgb(0, 104, 71);
}
.bwicon-icon-class-mexico .path4:before {
  content: '\eab7';
  margin-left: -1em;
  color: rgb(152, 157, 96);
}
.bwicon-icon-class-mexico .path5:before {
  content: '\eab8';
  margin-left: -1em;
  color: rgb(228, 182, 68);
}
.bwicon-icon-class-moldova .path1:before {
  content: '\eab9';
  color: rgb(255, 210, 0);
}
.bwicon-icon-class-moldova .path2:before {
  content: '\eaba';
  margin-left: -1em;
  color: rgb(0, 70, 174);
}
.bwicon-icon-class-moldova .path3:before {
  content: '\eabb';
  margin-left: -1em;
  color: rgb(179, 133, 81);
}
.bwicon-icon-class-moldova .path4:before {
  content: '\eabc';
  margin-left: -1em;
  color: rgb(0, 70, 174);
}
.bwicon-icon-class-moldova .path5:before {
  content: '\eabd';
  margin-left: -1em;
  color: rgb(204, 10, 47);
}
.bwicon-icon-class-mongolia .path1:before {
  content: '\eabe';
  color: rgb(0, 103, 179);
}
.bwicon-icon-class-mongolia .path2:before {
  content: '\eabf';
  margin-left: -1em;
  color: rgb(218, 32, 50);
}
.bwicon-icon-class-mongolia .path3:before {
  content: '\eac0';
  margin-left: -1em;
  color: rgb(255, 217, 0);
}
.bwicon-icon-class-montenegro .path1:before {
  content: '\eac1';
  color: rgb(211, 174, 59);
}
.bwicon-icon-class-montenegro .path2:before {
  content: '\eac2';
  margin-left: -1em;
  color: rgb(195, 2, 8);
}
.bwicon-icon-class-montenegro .path3:before {
  content: '\eac3';
  margin-left: -1em;
  color: rgb(107, 140, 61);
}
.bwicon-icon-class-montenegro .path4:before {
  content: '\eac4';
  margin-left: -1em;
  color: rgb(7, 91, 146);
}
.bwicon-icon-class-morocco .path1:before {
  content: '\eac5';
  color: rgb(224, 34, 40);
}
.bwicon-icon-class-morocco .path2:before {
  content: '\eac6';
  margin-left: -1em;
  color: rgb(8, 97, 47);
}
.bwicon-icon-class-mozambique .path1:before {
  content: '\eac7';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-mozambique .path2:before {
  content: '\eac8';
  margin-left: -1em;
  color: rgb(1, 113, 104);
}
.bwicon-icon-class-mozambique .path3:before {
  content: '\eac9';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-mozambique .path4:before {
  content: '\eaca';
  margin-left: -1em;
  color: rgb(252, 225, 0);
}
.bwicon-icon-class-mozambique .path5:before {
  content: '\eacb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-myanmar .path1:before {
  content: '\eacc';
  color: rgb(51, 178, 51);
}
.bwicon-icon-class-myanmar .path2:before {
  content: '\eacd';
  margin-left: -1em;
  color: rgb(254, 203, 0);
}
.bwicon-icon-class-myanmar .path3:before {
  content: '\eace';
  margin-left: -1em;
  color: rgb(234, 41, 57);
}
.bwicon-icon-class-myanmar .path4:before {
  content: '\eacf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-namibia .path1:before {
  content: '\ead0';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-namibia .path2:before {
  content: '\ead1';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-namibia .path3:before {
  content: '\ead2';
  margin-left: -1em;
  color: rgb(0, 53, 128);
}
.bwicon-icon-class-namibia .path4:before {
  content: '\ead3';
  margin-left: -1em;
  color: rgb(0, 149, 67);
}
.bwicon-icon-class-namibia .path5:before {
  content: '\ead4';
  margin-left: -1em;
  color: rgb(255, 206, 0);
}
.bwicon-icon-class-nepal .path1:before {
  content: '\ead5';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-nepal .path2:before {
  content: '\ead6';
  margin-left: -1em;
  color: rgb(0, 55, 147);
}
.bwicon-icon-class-nepal .path3:before {
  content: '\ead7';
  margin-left: -1em;
  color: rgb(220, 20, 60);
}
.bwicon-icon-class-nepal .path4:before {
  content: '\ead8';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-netherlands .path1:before {
  content: '\ead9';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-netherlands .path2:before {
  content: '\eada';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-netherlands .path3:before {
  content: '\eadb';
  margin-left: -1em;
  color: rgb(162, 0, 29);
}
.bwicon-icon-class-new-caledonia .path1:before {
  content: '\eadc';
  color: rgb(235, 44, 54);
}
.bwicon-icon-class-new-caledonia .path2:before {
  content: '\eadd';
  margin-left: -1em;
  color: rgb(29, 0, 254);
}
.bwicon-icon-class-new-caledonia .path3:before {
  content: '\eade';
  margin-left: -1em;
  color: rgb(0, 152, 52);
}
.bwicon-icon-class-new-caledonia .path4:before {
  content: '\eadf';
  margin-left: -1em;
  color: rgb(254, 255, 5);
}
.bwicon-icon-class-new-caledonia .path5:before {
  content: '\eae0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-new-zealand .path1:before {
  content: '\eae1';
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-new-zealand .path2:before {
  content: '\eae2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-new-zealand .path3:before {
  content: '\eae3';
  margin-left: -1em;
  color: rgb(204, 19, 44);
}
.bwicon-icon-class-nicaragua .path1:before {
  content: '\eae4';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-nicaragua .path2:before {
  content: '\eae5';
  margin-left: -1em;
  color: rgb(255, 224, 126);
}
.bwicon-icon-class-nicaragua .path3:before {
  content: '\eae6';
  margin-left: -1em;
  color: rgb(16, 46, 83);
}
.bwicon-icon-class-nicaragua .path4:before {
  content: '\eae7';
  margin-left: -1em;
  color: rgb(10, 165, 116);
}
.bwicon-icon-class-nicaragua .path5:before {
  content: '\eae8';
  margin-left: -1em;
  color: rgb(0, 127, 216);
}
.bwicon-icon-class-nigeria .path1:before {
  content: '\eae9';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-nigeria .path2:before {
  content: '\eaea';
  margin-left: -1em;
  color: rgb(2, 136, 81);
}
.bwicon-icon-class-north-america .path1:before {
  content: '\e910';
  color: rgb(209, 187, 196);
}
.bwicon-icon-class-north-america .path2:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(27, 65, 120);
}
.bwicon-icon-class-norway .path1:before {
  content: '\eaeb';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-norway .path2:before {
  content: '\eaec';
  margin-left: -1em;
  color: rgb(239, 43, 46);
}
.bwicon-icon-class-norway .path3:before {
  content: '\eaed';
  margin-left: -1em;
  color: rgb(1, 39, 104);
}
.bwicon-icon-class-northern-ireland .path1:before {
  content: '\eaee';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-northern-ireland .path2:before {
  content: '\eaef';
  margin-left: -1em;
  color: rgb(205, 0, 0);
}
.bwicon-icon-class-northern-ireland .path3:before {
  content: '\eaf0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-northern-ireland .path4:before {
  content: '\eaf1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-northern-ireland .path5:before {
  content: '\eaf2';
  margin-left: -1em;
  color: rgb(253, 206, 11);
}
.bwicon-icon-class-northern-ireland .path6:before {
  content: '\ebdb';
  margin-left: -1em;
  color: rgb(205, 0, 0);
}
.bwicon-icon-class-oman .path1:before {
  content: '\eaf3';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-oman .path2:before {
  content: '\eaf4';
  margin-left: -1em;
  color: rgb(219, 22, 28);
}
.bwicon-icon-class-oman .path3:before {
  content: '\eaf5';
  margin-left: -1em;
  color: rgb(0, 128, 2);
}
.bwicon-icon-class-pakistan .path1:before {
  content: '\eaf6';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-pakistan .path2:before {
  content: '\eaf7';
  margin-left: -1em;
  color: rgb(0, 63, 22);
}
.bwicon-icon-class-palestine .path1:before {
  content: '\eaf8';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-palestine .path2:before {
  content: '\eaf9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-palestine .path3:before {
  content: '\eafa';
  margin-left: -1em;
  color: rgb(0, 138, 40);
}
.bwicon-icon-class-palestine .path4:before {
  content: '\eafb';
  margin-left: -1em;
  color: rgb(254, 0, 0);
}
.bwicon-icon-class-panama .path1:before {
  content: '\eafc';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-panama .path2:before {
  content: '\eafd';
  margin-left: -1em;
  color: rgb(0, 103, 197);
}
.bwicon-icon-class-panama .path3:before {
  content: '\eafe';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-papua-new-guinea .path1:before {
  content: '\eaff';
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-papua-new-guinea .path2:before {
  content: '\eb00';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-papua-new-guinea .path3:before {
  content: '\eb01';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-papua-new-guinea .path4:before {
  content: '\eb02';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-paraguay .path1:before {
  content: '\eb03';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-paraguay .path2:before {
  content: '\eb04';
  margin-left: -1em;
  color: rgb(213, 44, 30);
}
.bwicon-icon-class-paraguay .path3:before {
  content: '\eb05';
  margin-left: -1em;
  color: rgb(0, 56, 168);
}
.bwicon-icon-class-paraguay .path4:before {
  content: '\eb06';
  margin-left: -1em;
  color: rgb(48, 113, 53);
}
.bwicon-icon-class-paraguay .path5:before {
  content: '\eb07';
  margin-left: -1em;
  color: rgb(239, 217, 30);
}
.bwicon-icon-class-peru .path1:before {
  content: '\eb08';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-peru .path2:before {
  content: '\eb09';
  margin-left: -1em;
  color: rgb(218, 7, 30);
}
.bwicon-icon-class-philippines .path1:before {
  content: '\eb0a';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-philippines .path2:before {
  content: '\eb0b';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-philippines .path3:before {
  content: '\eb0c';
  margin-left: -1em;
  color: rgb(0, 56, 168);
}
.bwicon-icon-class-philippines .path4:before {
  content: '\eb0d';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-poland .path1:before {
  content: '\eb0e';
  color: rgb(237, 38, 62);
}
.bwicon-icon-class-poland .path2:before {
  content: '\eb0f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-portugal .path1:before {
  content: '\eb10';
  color: rgb(1, 103, 0);
}
.bwicon-icon-class-portugal .path2:before {
  content: '\eb11';
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-portugal .path3:before {
  content: '\eb12';
  margin-left: -1em;
  color: rgb(254, 255, 0);
}
.bwicon-icon-class-portugal .path4:before {
  content: '\eb13';
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-portugal .path5:before {
  content: '\eb14';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-premier-league .path1:before {
  content: '\e911';
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-premier-league .path2:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-puerto-rico .path1:before {
  content: '\eb15';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-puerto-rico .path2:before {
  content: '\eb16';
  margin-left: -1em;
  color: rgb(222, 49, 42);
}
.bwicon-icon-class-puerto-rico .path3:before {
  content: '\eb17';
  margin-left: -1em;
  color: rgb(9, 24, 115);
}
.bwicon-icon-class-qatar .path1:before {
  content: '\eb18';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-qatar .path2:before {
  content: '\eb19';
  margin-left: -1em;
  color: rgb(142, 20, 59);
}
.bwicon-icon-class-romania .path1:before {
  content: '\eb1a';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-romania .path2:before {
  content: '\eb1b';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-romania .path3:before {
  content: '\eb1c';
  margin-left: -1em;
  color: rgb(1, 42, 127);
}
.bwicon-icon-class-russia .path1:before {
  content: '\eb1d';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-russia .path2:before {
  content: '\eb1e';
  margin-left: -1em;
  color: rgb(0, 57, 166);
}
.bwicon-icon-class-russia .path3:before {
  content: '\eb1f';
  margin-left: -1em;
  color: rgb(213, 44, 30);
}
.bwicon-icon-class-rwanda .path1:before {
  content: '\eb20';
  color: rgb(250, 210, 0);
}
.bwicon-icon-class-rwanda .path2:before {
  content: '\eb21';
  margin-left: -1em;
  color: rgb(0, 161, 222);
}
.bwicon-icon-class-rwanda .path3:before {
  content: '\eb22';
  margin-left: -1em;
  color: rgb(31, 96, 61);
}
.bwicon-icon-class-rwanda .path4:before {
  content: '\eb23';
  margin-left: -1em;
  color: rgb(250, 210, 0);
}
.bwicon-icon-class-saint-kitts-and-nevis .path1:before {
  content: '\eb24';
  color: rgb(253, 252, 18);
}
.bwicon-icon-class-saint-kitts-and-nevis .path2:before {
  content: '\eb25';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-saint-kitts-and-nevis .path3:before {
  content: '\eb26';
  margin-left: -1em;
  color: rgb(0, 153, 1);
}
.bwicon-icon-class-saint-kitts-and-nevis .path4:before {
  content: '\eb27';
  margin-left: -1em;
  color: rgb(254, 0, 2);
}
.bwicon-icon-class-saint-kitts-and-nevis .path5:before {
  content: '\eb28';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-salvador .path1:before {
  content: '\eb29';
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-salvador .path2:before {
  content: '\eb2a';
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.bwicon-icon-class-salvador .path3:before {
  content: '\eb2b';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-salvador .path4:before {
  content: '\eb2c';
  margin-left: -1em;
  color: rgb(109, 165, 68);
}
.bwicon-icon-class-salvador .path5:before {
  content: '\eb2d';
  margin-left: -1em;
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-san-marino .path1:before {
  content: '\eb2e';
  color: rgb(94, 182, 228);
}
.bwicon-icon-class-san-marino .path2:before {
  content: '\eb2f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-san-marino .path3:before {
  content: '\eb30';
  margin-left: -1em;
  color: rgb(96, 135, 88);
}
.bwicon-icon-class-san-marino .path4:before {
  content: '\eb31';
  margin-left: -1em;
  color: rgb(246, 196, 50);
}
.bwicon-icon-class-san-marino .path5:before {
  content: '\eb32';
  margin-left: -1em;
  color: rgb(152, 200, 228);
}
.bwicon-icon-class-san-marino .path6:before {
  content: '\eb33';
  margin-left: -1em;
  color: rgb(96, 135, 88);
}
.bwicon-icon-class-sao-tome-and-principe .path1:before {
  content: '\eb34';
  color: rgb(255, 206, 0);
}
.bwicon-icon-class-sao-tome-and-principe .path2:before {
  content: '\eb35';
  margin-left: -1em;
  color: rgb(21, 173, 44);
}
.bwicon-icon-class-sao-tome-and-principe .path3:before {
  content: '\eb36';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-sao-tome-and-principe .path4:before {
  content: '\eb37';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-saudi-arabia .path1:before {
  content: '\eb38';
  color: rgb(0, 108, 53);
}
.bwicon-icon-class-saudi-arabia .path2:before {
  content: '\eb39';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-scotland .path1:before {
  content: '\eb3a';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-scotland .path2:before {
  content: '\eb3b';
  margin-left: -1em;
  color: rgb(2, 101, 189);
}
.bwicon-icon-class-senegal .path1:before {
  content: '\eb3c';
  color: rgb(255, 218, 68);
}
.bwicon-icon-class-senegal .path2:before {
  content: '\eb3d';
  margin-left: -1em;
  color: rgb(73, 110, 45);
}
.bwicon-icon-class-senegal .path3:before {
  content: '\eb3e';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-serbia .path1:before {
  content: '\eb3f';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-serbia .path2:before {
  content: '\eb40';
  margin-left: -1em;
  color: rgb(13, 65, 119);
}
.bwicon-icon-class-serbia .path3:before {
  content: '\eb41';
  margin-left: -1em;
  color: rgb(199, 54, 61);
}
.bwicon-icon-class-serbia .path4:before {
  content: '\eb42';
  margin-left: -1em;
  color: rgb(225, 176, 45);
}
.bwicon-icon-class-serbia .path5:before {
  content: '\eb43';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-seychelles .path1:before {
  content: '\eb44';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-seychelles .path2:before {
  content: '\eb45';
  margin-left: -1em;
  color: rgb(252, 216, 85);
}
.bwicon-icon-class-seychelles .path3:before {
  content: '\eb46';
  margin-left: -1em;
  color: rgb(1, 122, 61);
}
.bwicon-icon-class-seychelles .path4:before {
  content: '\eb47';
  margin-left: -1em;
  color: rgb(214, 40, 39);
}
.bwicon-icon-class-seychelles .path5:before {
  content: '\eb48';
  margin-left: -1em;
  color: rgb(1, 63, 135);
}
.bwicon-icon-class-sierra-leone .path1:before {
  content: '\eb49';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-sierra-leone .path2:before {
  content: '\eb4a';
  margin-left: -1em;
  color: rgb(13, 90, 185);
}
.bwicon-icon-class-sierra-leone .path3:before {
  content: '\eb4b';
  margin-left: -1em;
  color: rgb(32, 171, 44);
}
.bwicon-icon-class-singapore .path1:before {
  content: '\eb4c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-singapore .path2:before {
  content: '\eb4d';
  margin-left: -1em;
  color: rgb(237, 42, 57);
}
.bwicon-icon-class-singapore .path3:before {
  content: '\eb4e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-slovakia .path1:before {
  content: '\eb4f';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-slovakia .path2:before {
  content: '\eb50';
  margin-left: -1em;
  color: rgb(12, 78, 162);
}
.bwicon-icon-class-slovakia .path3:before {
  content: '\eb51';
  margin-left: -1em;
  color: rgb(238, 29, 36);
}
.bwicon-icon-class-slovenia .path1:before {
  content: '\eb52';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-slovenia .path2:before {
  content: '\eb53';
  margin-left: -1em;
  color: rgb(15, 55, 140);
}
.bwicon-icon-class-slovenia .path3:before {
  content: '\eb54';
  margin-left: -1em;
  color: rgb(230, 36, 24);
}
.bwicon-icon-class-slovenia .path4:before {
  content: '\eb55';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-solomon-islands .path1:before {
  content: '\eb56';
  color: rgb(33, 91, 51);
}
.bwicon-icon-class-solomon-islands .path2:before {
  content: '\eb57';
  margin-left: -1em;
  color: rgb(0, 81, 186);
}
.bwicon-icon-class-solomon-islands .path3:before {
  content: '\eb58';
  margin-left: -1em;
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-solomon-islands .path4:before {
  content: '\eb59';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-south-africa .path1:before {
  content: '\eb5a';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-south-africa .path2:before {
  content: '\eb5b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-south-africa .path3:before {
  content: '\eb5c';
  margin-left: -1em;
  color: rgb(255, 183, 16);
}
.bwicon-icon-class-south-africa .path4:before {
  content: '\eb5d';
  margin-left: -1em;
  color: rgb(2, 121, 77);
}
.bwicon-icon-class-south-africa .path5:before {
  content: '\eb5e';
  margin-left: -1em;
  color: rgb(0, 35, 149);
}
.bwicon-icon-class-south-africa .path6:before {
  content: '\eb5f';
  margin-left: -1em;
  color: rgb(223, 55, 49);
}
.bwicon-icon-class-south-america .path1:before {
  content: '\ece5';
  color: rgb(187, 209, 208);
}
.bwicon-icon-class-south-america .path2:before {
  content: '\ecfb';
  margin-left: -1em;
  color: rgb(110, 55, 55);
}
.bwicon-icon-class-south-korea .path1:before {
  content: '\ecfc';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-south-korea .path2:before {
  content: '\ecfd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-south-korea .path3:before {
  content: '\ecfe';
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.bwicon-icon-class-south-korea .path4:before {
  content: '\ed03';
  margin-left: -1em;
  color: rgb(29, 90, 175);
}
.bwicon-icon-class-south-korea .path5:before {
  content: '\ed04';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.04;
}
.bwicon-icon-class-spain .path1:before {
  content: '\eb66';
  color: rgb(255, 196, 2);
}
.bwicon-icon-class-spain .path2:before {
  content: '\eb67';
  margin-left: -1em;
  color: rgb(198, 13, 31);
}
.bwicon-icon-class-sri-lanka .path1:before {
  content: '\eb68';
  color: rgb(255, 190, 41);
}
.bwicon-icon-class-sri-lanka .path2:before {
  content: '\eb69';
  margin-left: -1em;
  color: rgb(235, 115, 2);
}
.bwicon-icon-class-sri-lanka .path3:before {
  content: '\eb6a';
  margin-left: -1em;
  color: rgb(0, 84, 78);
}
.bwicon-icon-class-sri-lanka .path4:before {
  content: '\eb6b';
  margin-left: -1em;
  color: rgb(141, 22, 58);
}
.bwicon-icon-class-sudan .path1:before {
  content: '\eb6c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-sudan .path2:before {
  content: '\eb6d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-sudan .path3:before {
  content: '\eb6e';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-sudan .path4:before {
  content: '\eb6f';
  margin-left: -1em;
  color: rgb(0, 114, 41);
}
.bwicon-icon-class-superliga .path1:before {
  content: '\e912';
  color: rgb(255, 57, 0);
}
.bwicon-icon-class-superliga .path2:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-suriname .path1:before {
  content: '\eb70';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-suriname .path2:before {
  content: '\eb71';
  margin-left: -1em;
  color: rgb(180, 11, 45);
}
.bwicon-icon-class-suriname .path3:before {
  content: '\eb72';
  margin-left: -1em;
  color: rgb(55, 126, 63);
}
.bwicon-icon-class-suriname .path4:before {
  content: '\eb73';
  margin-left: -1em;
  color: rgb(236, 200, 27);
}
.bwicon-icon-class-swaziland .path1:before {
  content: '\eb74';
  color: rgb(251, 218, 0);
}
.bwicon-icon-class-swaziland .path2:before {
  content: '\eb75';
  margin-left: -1em;
  color: rgb(178, 10, 15);
}
.bwicon-icon-class-swaziland .path3:before {
  content: '\eb76';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-swaziland .path4:before {
  content: '\eb77';
  margin-left: -1em;
  color: rgb(63, 92, 184);
}
.bwicon-icon-class-swaziland .path5:before {
  content: '\eb78';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-sweden .path1:before {
  content: '\eb79';
  color: rgb(252, 202, 1);
}
.bwicon-icon-class-sweden .path2:before {
  content: '\eb7a';
  margin-left: -1em;
  color: rgb(1, 106, 166);
}
.bwicon-icon-class-switzerland .path1:before {
  content: '\eb7b';
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-switzerland .path2:before {
  content: '\eb7c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-syria .path1:before {
  content: '\eb7d';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-syria .path2:before {
  content: '\eb7e';
  margin-left: -1em;
  color: rgb(206, 15, 38);
}
.bwicon-icon-class-syria .path3:before {
  content: '\eb7f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-syria .path4:before {
  content: '\eb80';
  margin-left: -1em;
  color: rgb(1, 122, 61);
}
.bwicon-icon-class-taiwan .path1:before {
  content: '\eb81';
  color: rgb(254, 0, 0);
}
.bwicon-icon-class-taiwan .path2:before {
  content: '\eb82';
  margin-left: -1em;
  color: rgb(12, 0, 150);
}
.bwicon-icon-class-taiwan .path3:before {
  content: '\eb83';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-tajikistan .path1:before {
  content: '\eb84';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-tajikistan .path2:before {
  content: '\eb85';
  margin-left: -1em;
  color: rgb(1, 103, 0);
}
.bwicon-icon-class-tajikistan .path3:before {
  content: '\eb86';
  margin-left: -1em;
  color: rgb(204, 0, 0);
}
.bwicon-icon-class-tajikistan .path4:before {
  content: '\eb87';
  margin-left: -1em;
  color: rgb(248, 191, 2);
}
.bwicon-icon-class-tanzania .path1:before {
  content: '\eb88';
  color: rgb(252, 209, 22);
}
.bwicon-icon-class-tanzania .path2:before {
  content: '\eb89';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-tanzania .path3:before {
  content: '\eb8a';
  margin-left: -1em;
  color: rgb(30, 181, 58);
}
.bwicon-icon-class-tanzania .path4:before {
  content: '\eb8b';
  margin-left: -1em;
  color: rgb(0, 163, 221);
}
.bwicon-icon-class-thailand .path1:before {
  content: '\eb8c';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-thailand .path2:before {
  content: '\eb8d';
  margin-left: -1em;
  color: rgb(45, 42, 74);
}
.bwicon-icon-class-thailand .path3:before {
  content: '\eb8e';
  margin-left: -1em;
  color: rgb(165, 25, 49);
}
.bwicon-icon-class-togo .path1:before {
  content: '\eb8f';
  color: rgb(255, 206, 0);
}
.bwicon-icon-class-togo .path2:before {
  content: '\eb90';
  margin-left: -1em;
  color: rgb(1, 106, 78);
}
.bwicon-icon-class-togo .path3:before {
  content: '\eb91';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-togo .path4:before {
  content: '\eb92';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-trinidad-and-tobago .path1:before {
  content: '\eb93';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-trinidad-and-tobago .path2:before {
  content: '\eb94';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-trinidad-and-tobago .path3:before {
  content: '\eb95';
  margin-left: -1em;
  color: rgb(218, 26, 53);
}
.bwicon-icon-class-tunisia .path1:before {
  content: '\eb96';
  color: rgb(231, 0, 18);
}
.bwicon-icon-class-tunisia .path2:before {
  content: '\eb97';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-tunisia .path3:before {
  content: '\eb98';
  margin-left: -1em;
  color: rgb(231, 0, 18);
}
.bwicon-icon-class-turkey .path1:before {
  content: '\eb99';
  color: rgb(227, 11, 23);
}
.bwicon-icon-class-turkey .path2:before {
  content: '\eb9a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-turkmenistan .path1:before {
  content: '\eb9b';
  color: rgb(2, 132, 61);
}
.bwicon-icon-class-turkmenistan .path2:before {
  content: '\eb9c';
  margin-left: -1em;
  color: rgb(210, 38, 48);
}
.bwicon-icon-class-turkmenistan .path3:before {
  content: '\eb9d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-turkmenistan .path4:before {
  content: '\eb9e';
  margin-left: -1em;
  color: rgb(236, 187, 45);
}
.bwicon-icon-class-turks-and-caicos .path1:before {
  content: '\eb9f';
  color: rgb(49, 41, 99);
}
.bwicon-icon-class-turks-and-caicos .path2:before {
  content: '\eba0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-turks-and-caicos .path3:before {
  content: '\eba1';
  margin-left: -1em;
  color: rgb(252, 210, 16);
}
.bwicon-icon-class-turks-and-caicos .path4:before {
  content: '\eba2';
  margin-left: -1em;
  color: rgb(160, 83, 3);
}
.bwicon-icon-class-turks-and-caicos .path5:before {
  content: '\eba3';
  margin-left: -1em;
  color: rgb(0, 159, 71);
}
.bwicon-icon-class-turks-and-caicos .path6:before {
  content: '\eba4';
  margin-left: -1em;
  color: rgb(247, 2, 1);
}
.bwicon-icon-class-tuvalu .path1:before {
  content: '\eba5';
  color: rgb(0, 150, 200);
}
.bwicon-icon-class-tuvalu .path2:before {
  content: '\eba6';
  margin-left: -1em;
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-tuvalu .path3:before {
  content: '\eba7';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-tuvalu .path4:before {
  content: '\eba8';
  margin-left: -1em;
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-tuvalu .path5:before {
  content: '\eba9';
  margin-left: -1em;
  color: rgb(255, 206, 0);
}
.bwicon-icon-class-uganda .path1:before {
  content: '\ebaa';
  color: rgb(252, 220, 7);
}
.bwicon-icon-class-uganda .path2:before {
  content: '\ebab';
  margin-left: -1em;
  color: rgb(217, 0, 0);
}
.bwicon-icon-class-uganda .path3:before {
  content: '\ebac';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-uganda .path4:before {
  content: '\ebad';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-uganda .path5:before {
  content: '\ebae';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-ukraine .path1:before {
  content: '\ebaf';
  color: rgb(247, 207, 0);
}
.bwicon-icon-class-ukraine .path2:before {
  content: '\ebb0';
  margin-left: -1em;
  color: rgb(0, 89, 182);
}
.bwicon-icon-class-united-arab-emirates .path1:before {
  content: '\ebb1';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-united-arab-emirates .path2:before {
  content: '\ebb2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-united-arab-emirates .path3:before {
  content: '\ebb3';
  margin-left: -1em;
  color: rgb(2, 115, 47);
}
.bwicon-icon-class-united-arab-emirates .path4:before {
  content: '\ebb4';
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.bwicon-icon-class-uruguay .path1:before {
  content: '\ebb5';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-uruguay .path2:before {
  content: '\ebb6';
  margin-left: -1em;
  color: rgb(51, 138, 243);
}
.bwicon-icon-class-uruguay .path3:before {
  content: '\ebb7';
  margin-left: -1em;
  color: rgb(61, 113, 180);
}
.bwicon-icon-class-uruguay .path4:before {
  content: '\ebb8';
  margin-left: -1em;
  color: rgb(240, 197, 39);
}
.bwicon-icon-class-usa .path1:before {
  content: '\ebb9';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-usa .path2:before {
  content: '\ebba';
  margin-left: -1em;
  color: rgb(178, 35, 51);
}
.bwicon-icon-class-usa .path3:before {
  content: '\ebbb';
  margin-left: -1em;
  color: rgb(59, 57, 109);
}
.bwicon-icon-class-uzbekistan .path1:before {
  content: '\ebbc';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-uzbekistan .path2:before {
  content: '\ebbd';
  margin-left: -1em;
  color: rgb(216, 0, 0);
}
.bwicon-icon-class-uzbekistan .path3:before {
  content: '\ebbe';
  margin-left: -1em;
  color: rgb(30, 181, 58);
}
.bwicon-icon-class-uzbekistan .path4:before {
  content: '\ebbf';
  margin-left: -1em;
  color: rgb(4, 153, 181);
}
.bwicon-icon-class-uzbekistan .path5:before {
  content: '\ebc0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-vanuatu .path1:before {
  content: '\ebc1';
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-vanuatu .path2:before {
  content: '\ebc2';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-vanuatu .path3:before {
  content: '\ebc3';
  margin-left: -1em;
  color: rgb(0, 149, 67);
}
.bwicon-icon-class-vanuatu .path4:before {
  content: '\ebc4';
  margin-left: -1em;
  color: rgb(253, 207, 20);
}
.bwicon-icon-class-venezuela .path1:before {
  content: '\ebc5';
  color: rgb(1, 36, 125);
}
.bwicon-icon-class-venezuela .path2:before {
  content: '\ebc6';
  margin-left: -1em;
  color: rgb(207, 20, 43);
}
.bwicon-icon-class-venezuela .path3:before {
  content: '\ebc7';
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.bwicon-icon-class-venezuela .path4:before {
  content: '\ebc8';
  margin-left: -1em;
  color: rgb(255, 204, 2);
}
.bwicon-icon-class-vietnam .path1:before {
  content: '\ebc9';
  color: rgb(218, 38, 29);
}
.bwicon-icon-class-vietnam .path2:before {
  content: '\ebca';
  margin-left: -1em;
  color: rgb(254, 255, 0);
}
.bwicon-icon-class-wales .path1:before {
  content: '\ebcb';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-wales .path2:before {
  content: '\ebcc';
  margin-left: -1em;
  color: rgb(0, 171, 57);
}
.bwicon-icon-class-wales .path3:before {
  content: '\ebcd';
  margin-left: -1em;
  color: rgb(211, 15, 52);
}
.bwicon-icon-class-yemen .path1:before {
  content: '\ebce';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-yemen .path2:before {
  content: '\ebcf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-yemen .path3:before {
  content: '\ebd0';
  margin-left: -1em;
  color: rgb(239, 40, 0);
}
.bwicon-icon-class-zambia .path1:before {
  content: '\ebd1';
  color: rgb(27, 140, 0);
}
.bwicon-icon-class-zambia .path2:before {
  content: '\ebd2';
  margin-left: -1em;
  color: rgb(219, 31, 0);
}
.bwicon-icon-class-zambia .path3:before {
  content: '\ebd3';
  margin-left: -1em;
  color: rgb(255, 125, 0);
}
.bwicon-icon-class-zambia .path4:before {
  content: '\ebd4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-zambia .path5:before {
  content: '\ebd5';
  margin-left: -1em;
  color: rgb(255, 125, 0);
}
.bwicon-icon-class-zimbabwe .path1:before {
  content: '\ebd6';
  color: rgb(255, 255, 255);
}
.bwicon-icon-class-zimbabwe .path2:before {
  content: '\ebd7';
  margin-left: -1em;
  color: rgb(0, 98, 0);
}
.bwicon-icon-class-zimbabwe .path3:before {
  content: '\ebd8';
  margin-left: -1em;
  color: rgb(206, 1, 0);
}
.bwicon-icon-class-zimbabwe .path4:before {
  content: '\ebd9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bwicon-icon-class-zimbabwe .path5:before {
  content: '\ebda';
  margin-left: -1em;
  color: rgb(248, 204, 0);
}
.bwicon-search:before {
  content: '\ec38';
}
.bwicon-icon-clock:before {
  content: '\ec1e';
}
.bwicon-icon-chevron-up:before {
  content: '\ed05';
}
.bwicon-icon-arrow-increase:before {
  content: '\ed06';
}
.bwicon-icon-arrow-decrease:before {
  content: '\ed07';
}
.bwicon-t-loyalty-add-friend:before {
  content: '\ed08';
}
.bwicon-odds-boost-2:before {
  content: '\ed09';
}
.bwicon-icon-at-sign:before {
  content: '\ece3';
}
.bwicon-icon-timed-out:before {
  content: '\ecbe';
}
.bwicon-icon-odds-change-down:before {
  content: '\ec76';
}
.bwicon-icon-odds-change-up:before {
  content: '\ec77';
}
.bwicon-icon-live:before {
  content: '\ec74';
}
.bwicon-icon-tv:before {
  content: '\ec75';
}
.bwicon-info-no-circle:before {
  content: '\ec6b';
}
.bwicon-icon-edit:before {
  content: '\ec57';
}
.bwicon-icon-exclamation-mark:before {
  content: '\ec67';
}
.bwicon-icon-ui-copy:before {
  content: '\ec65';
}
.bwicon-external_link:before {
  content: '\ec63';
}
.bwicon-icon-passport:before {
  content: '\ec5a';
}
.bwicon-ic_document_passport:before {
  content: '\ec5a';
}
.bwicon-icon-car:before {
  content: '\ec2e';
}
.bwicon-ic_document_driver:before {
  content: '\ec2e';
}
.bwicon-icon-image-placeholder:before {
  content: '\ec60';
}
.bwicon-icon-add-file:before {
  content: '\ec5e';
}
.bwicon-icon-approved:before {
  content: '\ec5f';
}
.bwicon-icon-credit-card:before {
  content: '\ec5d';
}
.bwicon-ic_document_bank:before {
  content: '\ec5d';
}
.bwicon-icon-eye:before {
  content: '\ec5b';
}
.bwicon-ic_document_photo:before {
  content: '\ec5b';
}
.bwicon-icon-identification:before {
  content: '\ec5c';
}
.bwicon-icon-qa-not-filled:before {
  content: '\ec56';
}
.bwicon-news:before {
  content: '\ec3b';
}
.bwicon-leaderboard:before {
  content: '\ec3a';
}
.bwicon-calendar-frame:before {
  content: '\ec39';
}
.bwicon-gift:before {
  content: '\ec37';
}
.bwicon-icon_tabbar_play:before {
  content: '\ec34';
}
.bwicon-icon_tabbar_home:before {
  content: '\ec35';
}
.bwicon-icon-top-home:before {
  content: '\ec35';
}
.bwicon-icon-house-address:before {
  content: '\ec35';
}
.bwicon-ic_document_address:before {
  content: '\ec35';
}
.bwicon-icon-top-casino:before {
  content: '\ec36';
}
.bwicon-icon_tabbar_casino:before {
  content: '\ec36';
}
.bwicon-icon-account:before {
  content: '\ebe1';
}
.bwicon-ic_document_id:before {
  content: '\ebe1';
}
.bwicon-icon-banking:before {
  content: '\ebe2';
}
.bwicon-icon-responsible:before {
  content: '\ebfe';
}
.bwicon-icon-chevron-right:before {
  content: '\ec31';
}
.bwicon-icon-chevron-left:before {
  content: '\ec32';
}
.bwicon-icon-contact:before {
  content: '\ec2f';
}
.bwicon-icon-qa:before {
  content: '\ec30';
}
.bwicon-icon-arrows:before {
  content: '\ec21';
}
.bwicon-icon-chart:before {
  content: '\ec22';
}
.bwicon-icon-coins:before {
  content: '\ec23';
}
.bwicon-icon-transaction-chip:before {
  content: '\ebe3';
}
.bwicon-icon-transaction-withdraw:before {
  content: '\ec20';
}
.bwicon-icon-menu-settings:before {
  content: '\ec1b';
}
.bwicon-icon-menu-cashout:before {
  content: '\ec1c';
}
.bwicon-icon-menu-balance:before {
  content: '\ec1d';
}
.bwicon-icon-coupon:before {
  content: '\ec1a';
}
.bwicon-icon-question-mark-filled:before {
  content: '\ec19';
}
.bwicon-icon-load-fail:before {
  content: '\ec18';
}
.bwicon-icon-bets:before {
  content: '\ec17';
}
.bwicon-icon-mybets-active:before {
  content: '\ec14';
}
.bwicon-icon-mybets-cashout:before {
  content: '\ec15';
}
.bwicon-icon-mybets-archive:before {
  content: '\ec16';
}
.bwicon-icon-logout:before {
  content: '\ec10';
}
.bwicon-icon-alert-warn:before {
  content: '\ec4e';
}
.bwicon-bonus-coupon:before {
  content: '\ebf7';
}
.bwicon-icon-user:before {
  content: '\ebfd';
}
.bwicon-icon-plus:before {
  content: '\ebfb';
}
.bwicon-icon-shut-down:before {
  content: '\ebfc';
}
.bwicon-icon-cross-filled:before {
  content: '\ec0b';
}
.bwicon-icon-chevron-down-small:before {
  content: '\ec07';
}
.bwicon-icon-chevron-down:before {
  content: '\ec50';
}
.bwicon-icon-time-watch:before {
  content: '\ec09';
}
.bwicon-icon-top-live:before {
  content: '\ec09';
}
.bwicon-icon-lock:before {
  content: '\ec0a';
}
.bwicon-icon-search:before {
  content: '\ec00';
}
.bwicon-icon-favorite-filled:before {
  content: '\ec01';
}
.bwicon-icon-favorite:before {
  content: '\ec02';
}
.bwicon-icon-plus-filled:before {
  content: '\ec03';
}
.bwicon-icon-back:before {
  content: '\ec04';
}
.bwicon-icon-forward:before {
  content: '\ec0e';
}
.bwicon-icon-user1:before {
  content: '\ec05';
}
.bwicon-icon-menu:before {
  content: '\ec06';
}
.bwicon-icon-info:before {
  content: '\ebfa';
}
.bwicon-icon-casino:before {
  content: '\ebf8';
}
.bwicon-navigation_icon_slot_machines:before {
  content: '\ebf8';
}
.bwicon-icon-live-casino:before {
  content: '\ebf9';
}
.bwicon-navigation_icon_live_casino:before {
  content: '\ebf9';
}
.bwicon-icon-top-live-casino:before {
  content: '\ebf9';
}
.bwicon-icon-category-betwarrior-specials:before {
  content: '\ebff';
}
.bwicon-icon-cross:before {
  content: '\ec4f';
}
.bwicon-player_scoreboard_icon:before {
  content: '\ebe4';
}
.bwicon-player_stream_icon:before {
  content: '\ebe5';
}
.bwicon-icon-alert-info:before {
  content: '\ec1f';
}
.bwicon-info:before {
  content: '\ec0c';
}
.bwicon-icon-checkmark:before {
  content: '\ec11';
}
.bwicon-icon-offers:before {
  content: '\ec12';
}
.bwicon-icon-success:before {
  content: '\ec13';
}
